@charset "utf-8";

@import '../../_inc/variables.scss';
@import '../../_inc/mixins.scss';

// ベース
body {
  position: relative;
  color: $main_text;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", arial, sans-serif;
  line-height: 1.6;
  &.-fixed {
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

// ユーティリティー
.pagenation {
  margin-top: 25px;
  @include mq() {
    margin-top: 50px;
  }
}

.list-pagenation {
  margin: 0;
  padding: 0;
  list-style: none;
  > li {
    display: inline-block;
    a {
      display: block;
      padding: 5px 8px;
      border: 1px solid #d2d2d2;
      color: $main_text;
      @include fontCalc(14);
      line-height: 1;
      &.current,
      &:hover {
        background: #043a77;
        border-color: #043a77;
        color: #fff;
        text-decoration: none;
      }
      &.back span,
      &.next span {
        display: inline-block;
      }
      &.back span:before {
        content: "≪";
      }
      &.next span:after {
        content: "≫";
      }
    }
  }
}

.list-pages {
  margin-bottom: 2rem;
  padding: 0;
  @include fontCalc(14);
  list-style: none;
  text-align: center;
  @include mq(sp) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-top: 1px solid #ccc;
  }
  @include mq() {
    margin-bottom: 4rem;
    border-bottom: 1px solid #ccc;
    letter-spacing: -.4em;
  }
  > li {
    @include mq(sp) {
      width: 50%;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -ms-flex-direction: column;
      flex-direction: column;
      &:nth-child(odd) {
        > a {
          border-right: none;
        }
      }
    }
    @include mq() {
      display: inline-block;
      letter-spacing: normal;
      &:last-child {
        border-right: 1px solid #ccc;
      }
    }
    > a {
      display: block;
      border: 1px solid #ccc;
      color: $main_text;
      text-decoration: none;
      @include mq(sp) {
        padding: 0.5rem 0;
        border-top: none;
      }
      @include mq() {
        padding: 0.5rem 2em;
        border-right: none;
        border-bottom: none;
      }
      &.current,
      &:hover {
        background: #028541;
        border-color: #028541;
        color: #fff;
      }
    }
  }
}

// ヘッダー
.global-header {
  @include mq() {
    background: #fff;
    &.-fixed {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 10001;
    }
  }
}

.global-header__wrapper {
  position: relative;
  @include mq() {
    padding: 15px 20px 6px;
  }
}

.header-hgroup {
  @include mq(sp) {
    padding: 10px;
  }
  @include mq() {
    float: left;
    width: 50%;
  }
  > * {
    float: left;
    line-height: 0;
    > a {
      display: block;
    }
  }
  > .header-site__logo {
    margin: 0;
    max-width: 62%;
    @include mq() {
      padding-right: 1%;
      max-width: 56%;
      border-right: 1px solid #D2D2D2;
    }
  }
  > .header-theme__logo {
    margin: 0;
    max-width: 34%;
    @include mq(sp) {
      float: right;
    }
    @include mq() {
      padding-left: 1%;
      max-width: 32%;
    }
  }
}

.header-links {
  @include mq(sp) {
    position: relative;
    height: 50px;
    background: rgba(255, 255, 255, 0.6);
    &.-fixed {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 15000;
    }
  }
  @include mq() {
    float: right;
    width: 50%;
  }
}

.header-contact {
  @include mq(sp) {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10003;
  }
  @include mq() {
    margin-left: 2vw;
    float: right;
  }
}

.header-list__contact {
  margin: 0;
  padding: 0;
  list-style: none;
  > li {
    float: left;
    &:last-child {
      margin-left: 5px;
    }
    > a {
      display: block;
      padding: 1em 1.5em;
      color: #FFF;
      @include fontCalc(13);
      line-height: 1;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      @include mq() {
        @include fontCalc(14);
      }
      &.btn-contact {
        background: #990000;
      }
      &.btn-request {
        background: #4c6749;
      }
      &:hover {
        opacity: 0.7;
      }
      > span {
        display: inline-block;
        padding-right: 15px;
        background: url(../img/header-icon_arrow.png) no-repeat right center;
        background-size: 9px auto;
      }
    }
  }
}

.header-search {
  @include mq(sp) {
    display: none;
  }
  > * {
    @include mq() {
      float: right;
    }
  }
  &.-en__search {
    display: none;
  }
}

.btn-member {
  @include mq() {
    position: relative;
    display: block;
    margin: -3px 0 0 5px;
    padding: 15px 2.5vw;
    background: #a38a3f;
    color: #FFF;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    &:hover {
      color: #FFF;
      opacity: 0.7;
      text-decoration: none;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0.75%;
      top: 3%;
      display: block;
      width: 98%;
      height: 94%;
      border: 1px solid #FFF;
    }
  }
}

.form-search__site {
  position: relative;
  @include mq(sp) {
    margin: 10px auto;
    width: 80%;
  }
  @include mq() {
    margin-top: 3px;
  }
  > input[type="text"] {
    width: 100%;
    border: none;
    border-radius: 5px;
    line-height: 30px;
    text-indent: 8px;
    @include mq(sp) {
      padding-right: 32px;
      background: url(https://s.yimg.jp/images/search/customsearch/yjlogo/yjlogo_type4.gif) #fff center center no-repeat;
      &.nomark {
        background-image: none;
      }
    }
    @include mq() {
      width: 36px;
      border: 1px solid #999;
      border-radius: 48px;
      cursor: pointer;
      line-height: 30px;
      text-indent: 15px;
      &.-full {
        background: url(https://s.yimg.jp/images/search/customsearch/yjlogo/yjlogo_type4.gif) 8px center no-repeat;
      }
    }
    &:focus {
      outline: none;
    }
  }
  > input[type="submit"] {
    position: absolute;
    display: block;
    padding: 0;
    width: 20px;
    height: 20px;
    border: none;
    text-indent: -9999px;
    @include mq(sp) {
      right: 6px;
      top: 6px;
      background: url(../img/header-icon_search_b.png) no-repeat center transparent;
      background-size: 100%;
    }
    @include mq() {
      right: 8px;
      top: 6px;
      background: url(../img/header-icon_search_g.png) no-repeat transparent;
      cursor: pointer;
    }
  }
}

// ナビゲーション
.global-nav {
  position: relative;
  &.-pc {
    @include mq(sp) {
      display: none;
    }
    @include mq() {
      position: relative;
      display: block;
      background: #999;
      box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.15);
    }
    .list-global__nav {
      display: table;
      margin: 0;
      padding-left: 0;
      width: 100%;
      > li {
        display: table-cell;
        background: #043a77;
        text-align: center;
        &:hover {
          > .list-gn__child {
            display: table;
          }
        }
        &.bg-gray {
          background: #666;
        }
        > a {
          position: relative;
          display: block;
          padding: 1.375rem 2rem;
          background: #043a77;
          box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.15);
          color: #FFF;
          @include fontCalc(18);
          font-weight: bold;
          line-height: 1;
          &:hover {
            background: #FFF;
            color: #043a77;
            text-decoration: none;
          }
          &.btn-en {
            padding: 1.375rem 1rem;
            background: #666;
            font-weight: normal;
            &:hover {
              background: #FFF;
              color: #666;
            }
          }
        }
      }
      .list-gn__child {
        position: absolute;
        left: 0;
        top: 100%;
        display: none;
        padding-left: 0;
        width: 100%;
        line-height: 1;
        list-style: none;
        background: rgba(255, 255, 255, 0.85);
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
        z-index: 150;
        > li {
          margin: auto;
          max-width: 1200px;
          width: 95%;
        }
      }
      .inner-l {
        display: table;
        width: 100%;
        &.-compact {
          width: auto;
        }
      }
      .child-col {
        position: relative;
        display: table-cell;
        padding: 15px 0 20px;
        border-top: 5px solid transparent;
        &:hover {
          background: rgba(255, 255, 255, 0.85);
          border-top-color: #028541;
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
          .list-gn__grandchild {
            display: block;
            word-wrap: break-word;
          }
        }
        &:last-child {
          > a {
            border-right: 1px solid #666;
          }
        }
        > a {
          display: block;
          padding: 0.5rem 1rem;
          border-left: 1px solid #666;
          color: $main_text;
          font-weight: bold;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .list-gn__grandchild {
        position: absolute;
        top: 100%;
        display: none;
        padding: 15px 10px;
        width: 100%;
        background: rgba(255, 255, 255, 0.85);
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
        list-style: none;
        > li {
          margin-bottom: 10px;
          padding-left: 13px;
          background: url(../img/header-icon_arrow_g.png) no-repeat left 3px;
          background-size: 9px auto;
          text-align: left;
          &:last-child {
            margin-bottom: 0;
          }
          > a {
            color: #028541;
            @include fontCalc(15);
            font-weight: bold;
          }
        }
      }
    }
  }
  &.-sp {
    @include mq(sp) {
      display: block;
    }
    @include mq() {
      display: none;
    }
    position: absolute;
    display: none;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10002;
    .btn-menu {
      display: block;
      width: 50px;
      height: 50px;
      background: url(../img/menu-icon_open.png) no-repeat left top #000;
      background-size: 100%;
      text-indent: -9999px;
      &.-isOpen {
        background-image: url(../img/menu-icon_close.png);
      }
      > a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .srchBox.-en__search {
      display: none;
    }
    .form-search__site,
    .nav-wrap {
      display: none;
    }
    .nav-wrap {
      position: absolute;
      overflow: scroll;
      width: 100%;
    }
    .btn-en {
      margin: 0;
      > a {
        display: block;
        padding: 10px 0 10px 1em;
        background: url(../img/header-icon_arrow.png) no-repeat 95% center #666;
        background-size: 9px auto;
        color: #FFF;
      }
    }
    .btn-member {
      margin: 0;
      > a {
        display: block;
        padding: 10px 0 10px 1em;
        background: url(../img/header-icon_arrow.png) no-repeat 95% center #a38a3f;
        background-size: 9px auto;
        color: #FFF;
        font-weight: bold;
      }

    }
    .list-global__nav {
      margin: 0;
      padding-left: 0;
      > li {
        > a {
          display: block;
          padding: 10px 0 10px 1em;
          background: url(../img/menu-icon_plus_w.png) no-repeat 95% center #043a77;
          background-size: 11px auto;
          border-bottom: 1px solid #FFF;
          color: #FFF;
          font-weight: bold;
          &.-direct {
            background-image: url(../img/header-icon_arrow.png);
            background-size: 9px auto;
          }
          &.-isOpen {
            background-image: url(../img/menu-icon_minus_w.png);
          }
        }
      }
    }
    .list-gn__child {
      display: none;
      padding-left: 0;
    }
    .child-col {
      border-bottom: 1px solid #FFF;
      > a {
        display: block;
        padding: 10px 0 10px 2em;
        background: url(../img/menu-icon_plus_b.png) no-repeat 95% center #dedede;
        background-size: 11px auto;
        color: $main_text;
        font-weight: bold;
        @include fontCalc(15);
        &.-direct {
          background-image: url(../img/header-icon_arrow_g.png);
          background-size: 9px auto;
        }
        &.-isOpen {
          background-image: url(../img/menu-icon_minus_b.png);
        }
      }
    }
    .list-gn__grandchild {
      padding-left: 0;
      display: none;
      > li {
        border-bottom: 1px solid #DADADA;
        &:last-child {
          border-bottom: none;
        }
        > a {
          display: block;
          padding: 10px 0 10px 3em;
          background: url(../img/icon-arrow_d.png) no-repeat 2em center #FFF;
          background-size: 9px auto;
          color: $main_text;
          @include fontCalc(15);
        }
      }
    }
  }
}

.global-nav.-pc .list-global__nav.-member > li {
  background: #a38a3f;
  > a {
    background: #a38a3f;
    &:hover {
      background: #fff;
      color: #a38a3f;
    }
  }
}

.hero-img {
  height: 200px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @include mq() {
    height: 400px;
  }
}

.header-page__title {
  padding-top: 50px;
  @include mq() {
    padding-top: 140px;
  }
}

.content {
  margin-top: -50px;
  @include mq() {
    margin-top: -150px;
  }
}

.breadcrumb {
  padding: 8px;
  background: rgba(0, 0, 0, 0.7);
}

.list-breadcrumb {
  margin: 0;
  padding: 0;
  color: #FFF;
  list-style: none;
  line-height: 1;
  > li {
    display: inline-block;
    @include fontCalc(12);
    &:not(:first-child):before {
      content: ">";
      display: inline-block;
      color: #FFF;
    }
    > a {
      color: #FFF;
      text-decoration: underline;
    }
  }
}

// 広告エリア
.list-ad {
  margin: 55px auto 36px;
  padding-left: 0;
  list-style: none;
  @include mq() {
    margin: 90px auto;
  }
  > li {
    float: left;
    @include mq(sp) {
      margin-bottom: 2.5%;
      width: 48.75%;
      &:nth-child(even) {
        float: right;
      }
    }
    @include mq() {
      margin: 0 0 0.2% 0.2%;
      width: 16.5%;
      &:nth-child(6n + 1) {
        margin-left: 0;
      }
    }
    > a {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

// フッター
.btn-pagetop {
  position: fixed;
  right: 0;
  bottom: 0;
  display: block;
  margin: 0;
  width: 37px;
  height: 37px;
  z-index: 900;
  @include mq() {
    width: 60px;
    height: 60px;
  }
  &.-footerFixed {
    position: absolute;
  }
  > a {
    display: block;
    background: rgba(1, 58, 119, 0.7);
    &:hover {
      background: rgba(1, 58, 119, 1);
    }
  }
}

.global-footer__wrap {
  padding: 50px 0;
  background: #666;
  @include mq(sp) {
    display: none;
  }
}

.footer-column {
  float: left;
  padding-left: 2%;
  width: 16.6%;
  > h3 {
    margin-bottom: 10px;
    color: #FFF;
    @include fontCalc(15);
    > a {
      color: #FFF;
    }
  }
}

.footer-list__site {
  padding-left: 0;
  list-style: none;
  @include fontCalc(13);
  a {
    color: #CCC;
  }
}

.copyright {
  margin: auto;
  padding: 20px 0;
  background: #FFF;
  @include mq(sp) {
    border-top: 1px solid #e5e5e5;
  }
  @include mq() {
    padding: 35px 0;
  }
  img {
    @include mq(sp) {
      display: block;
      margin: auto;
      max-width: 80%;
    }
    @include mq() {
      float: left;
    }
  }
  .footer-contact {
    @include mq(sp) {
      margin-top: 15px;
    }
    @include mq() {
      float: left;
    }
    > dl {
      @include fontCalc(13);
      @include mq() {
        float: left;
        margin-left: 1rem;
      }
      > dt {
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eaeaea;
        @include mq(sp) {
          text-align: center;
        }
      }
    }
  }
}

.menu-overlay {
  @include mq(sp) {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10001;
  }
}